import './shrine.scss';
const Shrine = (props) => (
	<div className={"shrine shrine--" + props.active} onClick={props.choose}>
		<div className="shrine__name">{props.name}</div>
		<div className="shrine__fee">{props.fee} $CANDLE per Crystal</div>
	</div>
)

export const shrineNames = [
    "Maxus' Shrine",
    "She's only 13",
    "BOB",
    "Cult Dominator",
    "Sweet Shrine O' Mine",
    "Stonehenge",
    "Shrine o' Skullys",
    "1000 Corpses",
    "Woaw, am I a shrine?",
    "JesusWasHere",
    "NetMoney",
    "Cult DAO Shrine"
  ];

const shrineFees = [
	13,
	13,
	8,
	30,
	5,
	17,
	12,
	10,
	20,
	8,
	20,
	30
];

export const Shrines = (props) => (
	<div className="shrines">
		<p>This list is manually updated on Friday afternoons. Please keep it set to the DAO Shrine unless you know what you are doing.</p>
		{
			shrineNames.map((name, index)=>(
				<Shrine
					active={(props.active == index) ? true : false}
					name={name}
					fee={shrineFees[index]}
					choose={(typeof props.choose == "function") ? props.choose(index) : ()=>{}}
				/>	

				))
		}
	</div>
)

