//-------------------------------------------------------------------------------------------------------
// Farms and Pools
export const Pool = (props)=>(
<div className={"modal pool pool--"+props.poolUniqueID+" pool-retired--" + props.isRetired}>
  <h3>{props.poolName}</h3>
  <div className="img"><img src={props.tokenImg} /></div>
  <p>Staked: {props.poolBalance} ${props.stakingToken}</p>
  <p>Rewards: {props.pendingRewards} $CANDLE</p>
  <p className="gate">{props.gate}</p>
  <div className={"input"}>
    <input ref={props.theInputRef} /><button className="stake" onClick={props.stake}>Stake</button>
  </div>
  <button className="unstake" onClick={props.unstake}>Unstake</button>
  <button className="claim" onClick={props.claim}>Claim</button>
  <div className="close-modal" onClick={props.closeScreen}>
	<div>x</div>
  </div>
</div>
)

export const getUserInfo = (farmContract, poolID, callback = ()=>{}) => {
    farmContract.methods.userInfo(poolID, window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
        console.log(res)
      })
  }

export const getPendingCandle = (farmContract, poolID, callback = ()=>{}) => {
	farmContract.methods.pendingCandle(poolID, window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
        console.log(res)
      })
}

export const deposit = (farmContract,farmContractAddress, poolID, amount, callback=()=>{}, handleError=()=>{}) => {
	let data = farmContract.methods.deposit(poolID,amount).encodeABI()
	window.ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: farmContractAddress,
            value: 0,
            data: data,
          },
        ],}).then((hash)=>{
        	callback(hash)
        }).catch((err)=>{
        	handleError(err)
        })
}

