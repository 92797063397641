import React from 'react'

import hopium from './images/hopium.png'
import pickaxe from './images/pickaxe.png'
import goggles from './images/goggles.png'
import pipe from './images/pipe.png'
import lantern from './images/lantern.png'
import { nfts } from './configs/CultNFTConfig.js'
import storefront from './images/store-front.gif'

import { tokens } from './configs/CultTokensConfig.js'

import pending from './images/pending.gif'
const ITEM_ADDY = nfts["items"]["address"]
const fs = (bal, dec = 18) => {
  let x = (typeof(bal) == "undefined")? "" : bal.toString()
  let digits, decimals
  let L = "0", R = "0"
  let output
  //console.log(x.length, dec)
  if (x.length > dec) {
    digits = x.length - dec
    L = x.slice(0,digits)
    R = x.slice(digits)
  } else {
    decimals = dec - x.length
    R = "0".repeat(decimals).concat(x)
  }
  output = L.concat(".").concat(R)
  //console.log(output)
  output = new Intl.NumberFormat().format(output)
  return output
}
function ItemShop (props) {

	  const [isPending, setIsPending] = React.useState(false);
  const [pendingText, setPendingText] = React.useState("Pending Transaction")
	const getTransactionReceipt = (hash, callback= ()=>{}) => {
  console.log(hash)
  window.ethereum.request({
    method: "eth_getTransactionReceipt",
    params: [hash]
  }).then((res)=>{
    if (res == null) {
      getTransactionReceipt(hash, callback)
    }
    callback(res)
  }).catch((err)=>{
    console.log(err)
  })
}


const estimateGas = (contract, data, saveError, callback = ()=>{}) => {
   window.ethereum.request(
        {
          method: "eth_estimateGas",
          params: [
            {
              from: window.ethereum.selectedAddress,
              to: contract,     
              data: data,
            },
          ],
        }
      ).then((gasAmount)=>{
        callback()
      }).catch((err)=>{

        saveError(err.data.message)
        alert(err.data.message)
      })
}

const sendRequest = (contract, data, callback = ()=>{}) => {
  setIsPending(true)

  window.ethereum.request({
    method: "eth_sendTransaction",
    params: [
      {
        from: window.ethereum.selectedAddress,
        to: contract,
        value: 0,
        data: data,
      },
    ],}).then((hash)=>{
      getTransactionReceipt(hash,(receipt)=>{
        if (receipt != null) {
          setIsPending(false)
          callback()
        }
      })
    }).catch((err)=>{
      setIsPending(false)
    })
}
 	// ------------------------------------------------------------------------------------------
  	// ------------------------------------------------------------------------------------------
	const [minted_item1, set_minted_item1] = React.useState(0)
	const [minted_item2, set_minted_item2] = React.useState(0)
	const [minted_item11, set_minted_item11] = React.useState(0)
	const [minted_item12, set_minted_item12] = React.useState(0)
	const [minted_item13, set_minted_item13] = React.useState(0)
	const [minted_item21, set_minted_item21] = React.useState(0)
	const [minted_item22, set_minted_item22] = React.useState(0)
	const [minted_item31, set_minted_item31] = React.useState(0)

	const [minted_item101, set_minted_item101] = React.useState(0)
	const [minted_item102, set_minted_item102] = React.useState(0) 
	const [minted_item103, set_minted_item103] = React.useState(0)
	const [minted_item104, set_minted_item104] = React.useState(0)
	const [minted_item105, set_minted_item105] = React.useState(0)
	const [minted_item106, set_minted_item106] = React.useState(0)

	const w = props.web3
	const cultItems = new w.eth.Contract(nfts["items"]["abi"], ITEM_ADDY)
	var candle = new w.eth.Contract(tokens["candle"]["abi"], tokens["candle"]["address"])

	const getMintedAmount = (id, setState) => {
	   cultItems.methods.getItem(id)
	      .call({from: window.ethereum.selectedAddress})
	      .then((res)=>{
	        setState(res["6"])
	      }) 
  	}
  	const mint1155transaction = (itemId, cost, callback = () => {}) => {
  	//  alert("You are now minting an item for "+ cost +" $CANDLE.")
      setIsPending(true)
      setPendingText("Minting an item for "+ cost + " $CANDLE.")

      let data = cultItems.methods.mintItem(itemId).encodeABI()

	  sendRequest(ITEM_ADDY, data, callback)	      
      
  }

  const mint1155x = (itemId, cost, callback = () => {}) => {
    candle.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
      
        if(Number(fs(res).replace(",","")) >= Number(cost)) {

          candle.methods.allowance(
            window.ethereum.selectedAddress,
            nfts["items"]["address"]
            ).call({from:window.ethereum.selectedAddress})
             .then((res)=> {
              if (Number(fs(res).replace(",","")) >= Number(cost)) {
                mint1155transaction(itemId,cost)
              } else {
                alert("In order to continue to mint, you must approve the spending of " + cost + " $CANDLE")
                setIsPending(true)
                setPendingText("Approving the shop to spend "+ cost + " $CANDLE.")
                let data = candle.methods.approve(nfts["items"]["address"], cost + "000000000000000000").encodeABI()
                sendRequest(tokens["candle"]["address"], data, (receipt)=>{
                	callback(receipt)
                    mint1155transaction(itemId,cost)
                })
               
                  
                
              }
             })
           } else { // user needs to buy candle
            alert("You do not have enough $CANDLE to purchase this item. Visit spooky.fi and add 0x208234F4f8B1bBEA59cfDc38666141654e851DCe as a custom token to purchase.")
           }
      }) 
  }

  const mint1155 = (itemId, cost = "5000", callback = () => {}) => {
    return () => {
      mint1155x(itemId, cost, callback)
    }
  }

  React.useEffect(()=>{
    if (props.isOpen) {
    	console.log("fetching minted amounts")
      getMintedAmount(1, set_minted_item1)
      getMintedAmount(2, set_minted_item2)
      getMintedAmount(11, set_minted_item11)
      getMintedAmount(12, set_minted_item12)
      getMintedAmount(13, set_minted_item13)
      getMintedAmount(21, set_minted_item21)
      getMintedAmount(22, set_minted_item22)
      getMintedAmount(31, set_minted_item31)

      getMintedAmount(101, set_minted_item101)
      getMintedAmount(102, set_minted_item102)
      getMintedAmount(103, set_minted_item103)
      getMintedAmount(104, set_minted_item104)
      getMintedAmount(105, set_minted_item105)
      getMintedAmount(106, set_minted_item106)

    }
  },[props.isOpen])
 return (
	<div className="item-shop">
		<div className="store"> 
          <div className={"store__img"}><img src={storefront} /></div>
          <div className="store__options">
            <button className="store__option" onClick={mint1155(1,1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple.png" />
              <span>Apple</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item1)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(2,1000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple_bite.png" />
              <span>Apple (..bitten)</span>
              <span>1,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item2)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(11,1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_plain.png" />
              <span>Briefcase</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item11)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(12,1000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase.png" />
              <span>Cult Briefcase</span>
              <span>1,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item12)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(13,3000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_bone.png" />
              <span>Bone Handle Briefcase</span>
              <span>3,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item13)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(21,1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key.png" />
              <span>Skeleton Key</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item21)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(22,3000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key_bone.png" />
              <span>Bone Key</span>
              <span>3,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item22)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(31,5000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/matchbook.png" />
              <span>Matchbook</span>
              <span>5,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item31)} / 42 minted</span>
            </button>
            
            <button className="store__option" onClick={mint1155(106,1000)} >
              <img className="nft-thumb" src={goggles} />
              <span>Goggles</span>
              <span>1000 $CANDLE</span>
              <span className="smaller">{Number(minted_item106)} / 69 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(103,1)}>
              <img className="nft-thumb" src={pickaxe}  />
              <span>Pickaxe</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item103)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(102,100)}>
              <img className="nft-thumb" src={lantern}  />
              <span>Lantern</span>
              <span>100 $CANDLE</span>
              <span className="smaller">{Number(minted_item102)} / 4000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(104,50)}>
              <img className="nft-thumb" src={hopium}  />
              <span>Hopium</span>
              <span>50 $CANDLE</span>
              <span className="smaller">{Number(minted_item104)} / 10000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(105,1)}>
              <img className="nft-thumb" src={pipe}  />
              <span>Pipe</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item105)} / 1,000,000 minted</span>
            </button>
            
          </div>
        </div>
        <div className={"modal modal--pending screen-showing--" + isPending}>
            <h2>Pending Transaction</h2>
            <div className="modal__inside">
              <p>{pendingText}</p>
              <div className="modal__img"><img src={pending} /></div>  
            </div>
          </div>
	</div>

	)
}

export default ItemShop